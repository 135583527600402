import { React, useEffect, useState } from "react";
import { Container, Row, Col, Button, Tabs, Tab } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import Mosaic from "../../components/Mosaic/Mosaic";
import TourTabs from "../../components/TourTabs/TourTabs";
import ContactForm from "../../components/ContactForm/ContactForm";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import FAQs from "../../components/FAQs/FAQs";
import { triggerHellobox } from "../../utils/utils";


const Amenities = () => {
    const { t } = useTranslation();

    const gallery = [
        {   title: t("amenities.gallery.image1"),
            subtitle: "",
            href:  "/images/amenities/EVAMAR_SC_ALBERCA_PPAL.png",
            thumb: "images/amenities/EVAMAR_SC_ALBERCA_PPAL.png" },
        {   title: t("amenities.gallery.image2"),
            subtitle: "",
            href:  "images/amenities/EVAMAR_SC_LOBBY.png",
            thumb: "images/amenities/EVAMAR_SC_LOBBY.png" },
        {   title: t("amenities.gallery.image3"),
            subtitle: "",
            href:  "images/amenities/EVAMAR_SC_GAMEROOM-BAR.png",
            thumb: "images/amenities/EVAMAR_SC_GAMEROOM-BAR.png" },
        {   title: t("amenities.gallery.image4"),
            subtitle: "",
            href:  "images/amenities/EVAMAR_SC_ARENERO_CAMAS.DE.PLAYA.png",
            thumb: "images/amenities/EVAMAR_SC_ARENERO_CAMAS.DE.PLAYA.png" },

        {   title: t("amenities.gallery.image5"),
            subtitle: "",
            href:  "images/amenities/EVAMAR_SC_PARKING.png",
            thumb: "images/amenities/EVAMAR_SC_PARKING.png" },
        {   title: t("amenities.gallery.image6"), 
            subtitle: "",
            href:  "images/amenities/EVAMAR_SC_CENTRAL.PATIO.png",
            thumb: "images/amenities/EVAMAR_SC_CENTRAL.PATIO.png" },
        {   title: t("amenities.gallery.image7"),
            subtitle: "",
            href:  "images/amenities/EVAMAR_SC_ACCESO.png",
            thumb: "images/amenities/EVAMAR_SC_ACCESO.png" },
        {   title: t("amenities.gallery.image8"),
            subtitle: "",
            href:  "images/amenities/EVAMAR_SC_JARDIN.BOTANICO-ASADORES.png",
            thumb: "images/amenities/EVAMAR_SC_JARDIN.BOTANICO-ASADORES.png" },
    ];

    return (
        <Layout>
            <section className="bg-secondary-med py-2">
                <Container className='text-center text-md-start mt-5'>
                    <Row className='pt-5 text-center justify-content-center'>
                        <Col md="6" className="text-start">
                            <h1 className="text-uppercase font-styled-bold text-white size-title" dangerouslySetInnerHTML={{ __html: t("amenities.hero.title") }}>
                            </h1>
                            <p className="mb-4 pe-5" dangerouslySetInnerHTML={{ __html: t("amenities.hero.desc") }}></p>
                            <Button variant="secondary-high" className="me-2 text-white mb-4" onClick={triggerHellobox}>{t("buttons.meetEvamar")}</Button>
                            <Button variant="secondary" className="text-white mb-4" href="https://evamar.mx/360/" target="_blank">{t("buttons.tour")}</Button>
                        </Col>
                        <Col md="6">
                            <div className="image-square mb-n5" style={{ backgroundImage: `url("images/amenities/EVM-Amenidades.png")` }}></div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-secondary-light py-5">
                <Container className='text-center text-md-start py-5'>
                    <Row className='justify-content-md-center'>
                        <Col md='6' id="amenities-icons" className="mb-3">
                            <Row className='justify-content-md-center'>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--car"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--games"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--card"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--dice"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--kitchen"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--tables"></span>
                                </Col>
                            </Row>
                            <Row className='justify-content-md-center'>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--sand"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--yoga"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--golf"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--home"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--laundry"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--security"></span>
                                </Col>
                            </Row>
                            <Row className='justify-content-md-center'>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--gym"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--meditation"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--bell"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--water"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--sundesk"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--bus"></span>
                                </Col>
                            </Row>
                            <Row className='justify-content-md-center'>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--weather"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--grill"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--beds"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--moto"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--bike"></span>
                                </Col>
                                <Col xs='4' md='2'>
                                    <span className="features-icon icon icon--packs"></span>
                                </Col>
                            </Row>
                        </Col>
                        <Col md='6'>
                            <Row className='justify-content-md-center'>
                                <Col>
                                    <ul className="amenities-list list-ol">
                                        <li><span>01</span>{t("home.amenities.pools")}</li>
                                        <li><span>02</span>{t("home.amenities.gym")}</li>
                                        <li><span>03</span>{t("home.amenities.terrace")}</li>
                                        <li><span>04</span>{t("home.amenities.restaurant")}</li>
                                        <li><span>05</span>{t("home.amenities.sun")}</li>
                                        <li><span>06</span>{t("home.amenities.lobby")}</li>
                                        <li><span>07</span>{t("home.amenities.grills")}</li>
                                        <li><span>08</span>{t("home.amenities.sundek")}</li>
                                        <li><span>09</span>{t("home.amenities.atv")}</li>
                                        <li><span>10</span>{t("amenities.bike")}</li>
                                        <li><span>11</span>{t("amenities.tools")}</li>
                                        <li><span>12</span>{t("amenities.security")}</li>
                                    </ul>
                                </Col>
                                <Col>
                                    <ul className="amenities-list list-ol">
                                        <li><span>13</span>{t("home.amenities.kids")}</li>
                                        <li><span>14</span>{t("home.amenities.travel")}</li>
                                        <li><span>15</span>{t("home.amenities.games")}</li>
                                        <li><span>16</span>{t("home.amenities.stargazing")}</li>
                                        <li><span>17</span>{t("amenities.excercise")}</li>
                                        <li><span>18</span>{t("home.amenities.kidszone")}</li>
                                        <li><span>19</span>{t("home.amenities.clean")}</li>
                                        <li><span>20</span>{t("home.amenities.laundry")}</li>
                                        <li><span>21</span>{t("home.amenities.maintenance")}</li>
                                        <li><span>22</span>{t("home.amenities.hotel")}</li>
                                        <li><span>23</span>{t("amenities.transport")}</li>
                                        <li><span>24</span>{t("amenities.parking")}</li>
                                    </ul>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-secondary-med">
                <Container className='text-center text-md-start pt-0 pb-5'>
                    <Row className='justify-content-md-center'>
                        <Mosaic className="mt-n5" images={gallery} />
                    </Row>
                </Container>
            </section>
            <section className="bg-secondary-med py-2">
                <TourTabs />
            </section>
            <section className="bg-secondary-med">
                <Container className='text-center text-md-start'>
                    <Row className='py-4 justify-content-md-center'>
                        <Col md="10">
                        <VideoPlayer url='https://www.youtube.com/watch?v=YE892wpS6_Y' cover='video/spot_EVAMAR-cover.png' caption={t("video.amenities.title")} desc={t("video.amenities.desc")} />
                        </Col>
                    </Row>
                </Container>
            </section>
            <ContactForm />
            { /*
                <FAQs />
                */}
        </Layout>
    )
}

export default Amenities;