import React, { useEffect, useState } from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Container, Row, Col } from "react-bootstrap";
import Carousel from 'react-bootstrap/Carousel';

const Slider = props => {

    const [slides,setSlides] = useState([]);

    useEffect(() => {
        setSlides(props.slides);
    },[]);

  return (
    <>
        <Carousel fade>
            {
                slides?.map((s,key) =>
                    <Carousel.Item key={key} style={{ backgroundImage: `url(${s?.image})` }}>
                        {
                            s?.title &&
                            <Carousel.Caption>
                                <Container className='text-center text-md-start'>
                                    <Row className='py-5 justify-content-md-center'>
                                        <Col xs lg="12">
                                            <h3 className="font-styled" dangerouslySetInnerHTML={{ __html: s?.title}}></h3>
                                            { s.link &&
                                                <p><a className="text-white" href={s?.link?.href}>{s?.link?.label}</a></p>
                                            }
                                        </Col>
                                    </Row>
                                </Container>
                            </Carousel.Caption>
                        }
                    </Carousel.Item>
                )
            }
            {/*<Carousel.Item>
                <img
                className="d-block w-100"
                src={require("../../assets/images/img-home-slide-1.png")}
                alt="Second slide"
                />

                <Carousel.Caption>
                    <Container className='text-center text-md-start'>
                        <Row className='py-5 justify-content-md-center'>
                            <Col xs lg="12">
                                <h3 className="font-styled">El primer <br />proyecto fraccional <br />en Sonora.</h3>
                                <p><a href="/#" className="text-white">¿Qué es fraccional?</a></p>
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img
                className="d-block w-100"
                src={require("../../assets/images/img-home-slide-2.png")}
                alt="Third slide"
                />

                <Carousel.Caption>
                    <Container className='text-center text-md-start'>
                        <Row className='py-5 justify-content-md-center'>
                            <Col xs lg="12">
                                <h3 className="font-styled">El primer <br />proyecto fraccional <br />en Sonora.</h3>
                                <p><a href="/#" className="text-white">¿Qué es fraccional?</a></p>
                            </Col>
                        </Row>
                    </Container>
                </Carousel.Caption>
            </Carousel.Item>*/}
        </Carousel>
    </>
  );
}

export default Slider;