import React from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FAQs = () => {
    const { t } = useTranslation();

    return (
        <div className='faqs'>
            <Container className='text-center text-md-start'>
                <Row className='pb-5 justify-content-md-center'>
                    <Col xs lg="12">
                        <h2 className='text-center mb-4'>{t("faqs.titleGeneral")}</h2>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>{t("faqs.q1.question")}</Accordion.Header>
                                <Accordion.Body>
                                {t("faqs.q1.answer")}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>{t("faqs.q2.question")}</Accordion.Header>
                                <Accordion.Body>
                                {t("faqs.q2.answer")}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>{t("faqs.q3.question")}</Accordion.Header>
                                <Accordion.Body>
                                {t("faqs.q3.answer")}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>{t("faqs.q4.question")}</Accordion.Header>
                                <Accordion.Body>
                                {t("faqs.q4.answer")}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>{t("faqs.q5.question")}</Accordion.Header>
                                <Accordion.Body>
                                {t("faqs.q5.answer")}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>{t("faqs.q6.question")}</Accordion.Header>
                                <Accordion.Body>
                                {t("faqs.q6.answer")}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>{t("faqs.q7.question")}</Accordion.Header>
                                <Accordion.Body>
                                {t("faqs.q7.answer")}
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>{t("faqs.q8.question")}</Accordion.Header>
                                <Accordion.Body>
                                {t("faqs.q8.answer")}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default FAQs;