import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { InlineWidget } from "react-calendly";

const ContactForm = () => {
    const { t } = useTranslation();
    
    return (
        <div className='contact-form bg-secondary'>
            <Container className='text-center text-md-start'>
                <Row className='py-5 justify-content-md-center'>
                    <Col xs lg="6">
                        <h2 className='text-center font-styled-bold text-white'>{t("contactForm.title")}</h2>
                        <InlineWidget url="https://calendly.com/evamarmx/reunion-de-60-minutos?primary_color=948867" />
                        { /*

                            <Form autoComplete='off'>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="text" placeholder="Nombre" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control type="email" placeholder="Email" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="phone" placeholder="Teléfono" />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control as="textarea" rows={3} placeholder="Mensaje" />
                                </Form.Group>

                                <Form.Group className="mb-5" controlId="formBasicCheckbox">
                                    <Form.Check type="checkbox" label="Acepto los términos y condiciones." />
                                </Form.Group>

                                <Button variant="secondary-high" type="submit">
                                    Submit
                                </Button>
                            </Form>*/
                        }
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactForm;