import React, { useEffect, useRef, useState } from 'react';
const PANOLENS = require("panolens");

const PanoViewer = ({image}) => {
    const viewerRef = useRef();
    
    let panorama, viewer;
    
    viewer = new PANOLENS.Viewer({
        enableReticle: false,
        container: viewerRef.current,
        output: "console",
        viewIndicator: false,
        autoRotate: true,
        autoRotateSpeed: 2,
        autoRotateActivationDuration: 5000,
        dwellTime: 2000,
        controlBar: false,
    });
    panorama = new PANOLENS.ImagePanorama( image );        
    viewer.add(panorama);
    useEffect(() => {
        console.warn("tour:",image);
        panorama = new PANOLENS.ImagePanorama( image );        
        viewer.setPanorama(panorama);
    },[image])

    return (
        <>
            <div className='panorama-viewer' ref={viewerRef}></div>
        </>
    )
}

export default PanoViewer;