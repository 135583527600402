import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import { LangContext } from '../../LangContext';

const Header = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { changeLang } = useContext(LangContext);
    const [curLang, setCurLang] = useState(localStorage.getItem("i18n") || 'es');
    const [navLangIcon, setNavLangIcon] = useState();

    const handleChangeLanguage = langCode => {
        changeLang(langCode);
        setCurLang(langCode);
    }
    useEffect(() => {
        setNavLangIcon(<span className='lang-flag' style={{backgroundImage: `url(images/ico-flag-${curLang}.svg)`}}></span>);
    },[curLang])

    const activeRouteClassName = route => location.pathname === route ? 'active' : '';

    return (
        <>
            <Navbar bg="primary" variant="dark" expand="lg" className='py-2' sticky='top'>
                <Container>
                    <Navbar.Brand href="/"><img className='mt-lg-2' height="45" src="images/lg-evamar.svg" alt="" /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-0 ms-auto">
                        <Nav.Link href="/fraccional" className={activeRouteClassName('/fraccional')} >{t("menu.fractional")}</Nav.Link>
                        <NavDropdown title={t("menu.apartment")} id="basic-nav-dropdown">
                            <NavDropdown.Item href="/amenidades">{t("menu.amenities")}</NavDropdown.Item>
                            <NavDropdown.Item href="/departamentos">{t("menu.apartments")}</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link href="#home" className='text-muted'>{t("menu.buy")}</Nav.Link>
                        <Nav.Link href="https://hotels.cloudbeds.com/reservation/XanvBG" target="_blank">{t("menu.rental")}</Nav.Link>
                        <Nav.Link href="/comunidad" className={activeRouteClassName('/comunidad')}>{t("menu.community")}</Nav.Link>
                        <Nav.Link href="/contacto" className={activeRouteClassName('/contacto')}>{t("menu.contact")}</Nav.Link>
                        <NavDropdown title={navLangIcon} id="basic-nav-dropdown">
                            <NavDropdown.Item onClick={() => handleChangeLanguage("es") }><span className='lang-flag' style={{backgroundImage: `url(images/ico-flag-es.svg)`}}></span> Español</NavDropdown.Item>
                            <NavDropdown.Item onClick={() => handleChangeLanguage("en") }><span className='lang-flag' style={{backgroundImage: `url(images/ico-flag-en.svg)`}}></span> English</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export default Header;