import { React } from "react";
import { Container, Row, Col, Button } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import ContactForm from "../../components/ContactForm/ContactForm";
import FAQs from "../../components/FAQs/FAQs";

const Community = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <section className="bg-secondary py-5">
                <Container className='text-center text-md-start'>
                    <Row className='pt-4 justify-content-md-center'>
                        <Col>
                            <h2 className='text-center font-styled-bold text-white'>{t("privacy.title")}</h2>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-secondary-light py-5">
                <Container className='text-md-start mt-5'>
                    <Row className='py-md-5 justify-content-center'>
                        <Col md="8" className="text-justify">
                            <p>De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, RETI SAPI DE CV pone a su disposición el siguiente aviso de privacidad. RETI SAPI DE CV es responsable del uso y protección de sus datos personales, en este sentido y atendiendo las obligaciones legales establecidas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, a través de este instrumento se informa a los titulares de los datos, la información que de ellos se recaba y los fines que se le darán a dicha información.</p>

                            <p>Los datos personales que recabamos de usted serán utilizados para las siguientes finalidades, las cuales son necesarias para concretar nuestra relación con usted, así como atender los servicios y/o pedidos que solicite:</p>

                            <p>Envió de promociones por correo electrónico</p>
                            <p>Registro de clientes</p>
                            <p>Acceso a información de RETI SAPI DE CV</p>
                            <p>Asesoría y consultoría</p>
                            <p>Mercadotecnia o publicitaria</p>
                            <p>Prospección comercial</p>
                            <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>

                            <p>Nombre: RETI SAPI DE CV</p>
                            <p>Teléfono: 6622672495</p>
                            <p>Correo electrónico: mvieyra@re-ti.mx</p>
                            <p>Datos técnicos de proyectos arquitectónicos</p>
                            <p>Por otra parte, informamos a usted, que sus datos personales no serán compartidos con ninguna autoridad, empresa, organización o persona distintas a nosotros y serán utilizados exclusivamente para los fines señalados.</p>

                            <p>Usted tiene en todo momento el derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); de igual manera, tiene derecho a que su información se elimine de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación); así como también a oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>

                            <p>Para el ejercicio de cualquiera de los derechos ARCO, se deberá presentar la solicitud respectiva a través de los formatos que estarán a su disposición en:</p>

                            <p>http://evamar.mx/aviso-de-privacidad</p>
                            <p>Lo anterior también servirá para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO, no obstante, la solicitud de ejercicio de estos derechos debe contener la siguiente información:</p>

                            <p>Nombre: </p>
                            <p>Fecha: </p>
                            <p>Teléfono: </p>
                            <p>Motivo de la solicitud: </p>
                            <p>La respuesta a la solicitud se dará en 2 semanas y se comunicará mediante correo electrónico.</p>

                            <p>Los datos de contacto de la persona o departamento de datos personales, que está a cargo de dar trámite a las solicitudes de derechos ARCO, son los siguientes:</p>

                            <p>a) Encargado: Departamento de información</p>
                            <p>b) 01 443 204 0150</p>
                            <p>Cabe mencionar, que en cualquier momento usted puede revocar su consentimiento para el uso de sus datos personales. Del mismo modo, usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales.</p>

                            <p>Asimismo, usted deberá considerar que, para ciertos fines, la revocación de su consentimiento implicará que no podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</p>

                            <p>Para revocar el consentimiento que usted otorga en este acto o para limitar su divulgación, se deberá presentar la solicitud respectiva a través de los formatos que estarán a su disposición.</p>

                            <p>Del mismo modo, podrá solicitar la información para conocer el procedimiento y requisitos para la revocación del consentimiento, así como limitar el uso y divulgación de su información personal.</p>

                            <p>La respuesta a la solicitud de revocación o limitación de divulgación de sus datos se dará a más tardar en 2 semanas y se comunicará mediante correo electrónico.</p>

                    

                        </Col>
                    </Row>
                </Container>
            </section>
            { /*
                <FAQs />
                */}
        </Layout>
    )
}

export default Community;