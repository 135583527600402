import { React } from "react";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Container, Row, Col } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import Slider from "../../components/Slider/Slider";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import Button from "react-bootstrap/Button";
import PropertyCard from "../../components/PropertyCard/PropertyCard";
import ContactForm from "../../components/ContactForm/ContactForm";
import FAQs from "../../components/FAQs/FAQs";
import { triggerHellobox } from "../../utils/utils";

import properties from "../../data/properties";

const Home = () => {
    const { t } = useTranslation();

    const mainSlider = [
        {
            id: 1,
            image: "./images/img-home-slide-0.jpg",
            alt: 'First Slide',
            title: t("home.slider.slide0.caption"),
            link: {
                label: t("home.slider.slide0.link"),
                href: "/fraccional",
            }
        },
        {
            id: 2,
            image: "./images/img-home-slide-1.jpg",
            alt: 'First Slide',
            title: t("home.slider.slide1.caption"),
            /*link: {
                label: '¿Qué es fraccional?',
                href: "#",
            }*/
        },
        {
            id: 3,
            image: "./images/img-home-slide-2.jpg",
            alt: 'Second Slide',
            title: t("home.slider.slide2.caption"),
            /*link: {
                label: '¿Qué es fraccional?',
                href: "#",
            }*/
        }
    ];
    const amenitiesSlider = [
        {
            id: 1,
            image: "./images/img-amenities-slide-1.jpg",
            alt: 'First Slide',
        },
        {
            id: 2,
            image: "./images/img-amenities-slide-2.jpg",
            alt: 'Second Slide',
        },
        {
            id: 3,
            image: "./images/img-amenities-slide-3.jpg",
            alt: 'Second Slide',
        }
    ];

    return (
        <>
            <Layout>
                <Slider slides={mainSlider} />
                <section id="features" className="bg-stripe bg-secondary-med pb-5">
                    <Container className='text-center text-md-start'>
                        <Row className='py-5 justify-content-md-center'>
                            <Col>
                                <VideoPlayer url='https://www.youtube.com/watch?v=9SWKrHzQfVU&rel=0' cover='video/evamar-video1-cover.png' caption={t("video.home.title")} desc={t("video.home.desc")} />
                            </Col>
                        </Row>
                    </Container>
                    <Container className='text-center text-md-start mt-5'>
                        <Row className='justify-content-md-center'>
                            <h2 className="text-center">{t("home.intro.title")}</h2>
                        </Row>
                        <Row className='pt-5 text-center'>
                            <Col className="px-5 py-3">
                                <div className="feature-item mx-auto">
                                    <span className="features-icon icon icon--accesible--color"></span><br />
                                    <h5 className="">{t("home.icons.accesible")}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: t("home.icons.accesible.desc") }}></p>
                                </div>
                            </Col>
                            <Col className="px-5 py-3">
                                <div className="feature-item mx-auto">
                                    <span className="features-icon icon icon--amenidades--color"></span><br />
                                    <h5 className="">{t("home.icons.amenities")}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: t("home.icons.amenities.desc") }}></p>
                                </div>
                            </Col>
                            <Col className="px-5 py-3">
                                <div className="feature-item mx-auto">
                                    <span className="features-icon icon icon--resort--color"></span><br />
                                    <h5 className="">{t("home.icons.resort")}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: t("home.icons.resort.desc") }}></p>
                                </div>
                            </Col>
                        </Row>
                        <Row className='pb-5 text-center'>
                            <Col className="px-5 py-3">
                                <div className="feature-item mx-auto">
                                    <span className="features-icon icon icon--fraccional--color"></span><br />
                                    <h5 className="">{t("home.icons.fractional")}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: t("home.icons.fractional.desc") }}></p>
                                </div>
                            </Col>
                            <Col className="px-5 py-3">
                                <div className="feature-item mx-auto">
                                    <span className="features-icon icon icon--equipado--color"></span><br />
                                    <h5 className="">{t("home.icons.furniture")}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: t("home.icons.furniture.desc") }}></p>
                                </div>
                            </Col>
                            <Col className="px-5 py-3">
                                <div className="feature-item mx-auto">
                                    <span className="features-icon icon icon--app--color"></span><br />
                                    <h5 className="">{t("home.icons.app")}</h5>
                                    <p dangerouslySetInnerHTML={{ __html: t("home.icons.app.desc") }}></p>
                                </div>
                            </Col>
                        </Row>
                        <Row className='text-center'>
                            <Col>
                                <Button href="/fraccional" variant="secondary" className="text-white">{t("buttons.meetEvamar")}</Button>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section id="amenities" className="bg-secondary-light">
                    <Container className='text-center text-md-start'>
                        <Row className='py-5 justify-content-md-between align-items-end'>
                            <Col md='5'>
                                <span className="deco-letter text-secondary-med">A.</span>
                                <p dangerouslySetInnerHTML={{ __html: t("home.amenities.intro") }}>
                                    
                                </p>
                                <Row className='justify-content-md-center mb-4'>
                                    <Col xs='5' md='12' lg='6' className="pe-0">
                                        <ul className="amenities-list list-ol">
                                            <li><span>01</span>{t("home.amenities.pools")}</li>
                                            <li><span>02</span>{t("home.amenities.gym")}</li>
                                            <li><span>03</span>{t("home.amenities.lobby")}</li>
                                            <li><span>04</span>{t("home.amenities.terrace")}</li>
                                            <li><span>05</span>{t("home.amenities.sun")}</li>
                                            <li><span>06</span>{t("home.amenities.stargazing")}</li>
                                            <li><span>07</span>{t("home.amenities.grills")}</li>
                                            <li><span>08</span>{t("home.amenities.sundek")}</li>
                                            <li><span>09</span>{t("home.amenities.atv")}</li>
                                            <li><span>10</span>{t("home.amenities.racks")}</li>
                                        </ul>
                                    </Col>
                                    <Col xs='7' md='12' lg='6' className="pe-0">
                                        <ul className="amenities-list list-ol">
                                            <li><span>11</span>{t("home.amenities.kids")}</li>
                                            <li><span>12</span>{t("home.amenities.travel")}</li>
                                            <li><span>13</span>{t("home.amenities.games")}</li>
                                            <li><span>14</span>{t("home.amenities.restaurant")}</li>
                                            <li><span>15</span>{t("home.amenities.kidszone")}</li>
                                            <li><span>16</span>{t("home.amenities.clean")}</li>
                                            <li><span>17</span>{t("home.amenities.laundry")}</li>
                                            <li><span>18</span>{t("home.amenities.maintenance")}</li>
                                            <li><span>19</span>{t("home.amenities.hotel")}</li>
                                        </ul>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button href="/amenidades" variant="secondary" className="text-white mb-4">{t("home.amenities.more")}</Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md='6'>
                                <Slider slides={amenitiesSlider} />
                            </Col>
                        </Row>
                        <hr className="text-secondary" />
                        <Row className='mt-5 justify-content-md-center'>
                            <Col>
                                <Row className='justify-content-md-start align-items-center'>
                                    <Col md='2'>
                                        <span className="deco-letter text-secondary-med">D.</span>
                                    </Col>
                                    <Col md='3'>
                                        <p dangerouslySetInnerHTML={{ __html: t("home.apartments.intro") }}>
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className='py-5 justify-content-md-center'>
                                {
                                    properties.map((p,key) => 
                                        <Col className="px-4 text-center" key={key}>
                                            <PropertyCard variant="circle" property={p}  key={key} />
                                        </Col>
                                    )
                                }
                        </Row>
                    </Container>
                </section>
                <section className="bg-secondary-med camp camp--wordlist">
                    <Container className='text-center text-md-start'>
                        <Row className='py-5'>
                            <Col xs='8' md='9' className="camp--bg" style={{backgroundImage: `url("./images/img-camp-experiencias.png")`}}>
                            </Col>
                            <Col xs='4' md='3'>
                                <div className="camp--caption">
                                    <p className="text-secondary" dangerouslySetInnerHTML={{ __html: t("home.campaign.community.caption") }}>
                                    </p>
                                    <div className="text-end">
                                        <Button variant="primary" className="text-white" onClick={triggerHellobox}>{t("buttons.discoverEvamar")}</Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <ContactForm />
                { /*
                <FAQs />
                */}
            </Layout>
        </>
    )
}

export default Home;