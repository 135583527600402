import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PanoViewer from "../../components/PanoViewer/PanoViewer";

const TourTabs = () => {
    const { t } = useTranslation();
    const [tour, setTour] = useState();
    const tours = [
        "./images/tours/360-lobby.jpg",
        "./images/tours/360-acceso.jpg",
        "./images/tours/360-alberca_gym.jpg",
        "./images/tours/360-juegos_bar.jpg"
    ];

    const changeTour = idx => {
        setTour({
            active: idx,
            image: tours[idx]
        });
    }
    useEffect(() => {
        changeTour(0);
    },[])

    return (
        <Container className='text-center text-md-start pt-5 pb-5'>
            <Row className='justify-content-md-center'>
                <h2 className='text-center size-title font-styled-bold text-white'>{t("amenities.tours.title")}</h2>
            </Row>
            <Row className='justify-content-center'>
                <Col className="text-center">
                    <div className="tours-list mb-3">
                        <Button variant="text" className={tour?.active === 0 ? 'active' : ''} onClick={() => changeTour(0)}>{t("amenities.tours.tab1")}</Button>
                        <Button variant="text" className={tour?.active === 1 ? 'active' : ''} onClick={() => changeTour(1)}>{t("amenities.tours.tab2")}</Button>
                        <Button variant="text" className={tour?.active === 2 ? 'active' : ''} onClick={() => changeTour(2)}>{t("amenities.tours.tab3")}</Button>
                        <Button variant="text" className={tour?.active === 3 ? 'active' : ''} onClick={() => changeTour(3)}>{t("amenities.tours.tab4")}</Button>
                    </div>
                    <PanoViewer image={tour?.image}/>
                </Col>
            </Row>
        </Container>
    )
}

export default TourTabs;