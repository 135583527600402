import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import BeforeLeaveModal from '../BeforeLeaveModal/BeforeLeaveModal';
import { PopupWidget } from "react-calendly";
import { useState, useEffect, useRef } from 'react';

const Layout = ({children, onHandleOpenCalendly}) => {

    // Calendly Popup Trigger (when pointer leaves out the page)
    const calendlyPopupButton = useRef(null);

    const openCalendly = () => {
        setOpenBeforeLeaveModal(false);
        calendlyPopupButton.current.setState({ isOpen: true });
    }
    const [openBeforeLeaveModal, setOpenBeforeLeaveModal] = useState(false);
    useEffect(() => {
        document.addEventListener('mouseleave', () => {
            if(!document.querySelector(".calendly-overlay") && !localStorage.getItem("evamar-popup")){
                setOpenBeforeLeaveModal(true);
                localStorage.setItem("evamar-popup","true");
            }
        });
    },[])
    const handleOpenCalendly = () => {
        setOpenBeforeLeaveModal(false);
        openCalendly();
    }
    const handleCloseModal = () => {
        setOpenBeforeLeaveModal(false);
    }

    return(
        <> 
            <BeforeLeaveModal show={openBeforeLeaveModal} onHandleOpenCalendly={handleOpenCalendly} onHandleClose={handleCloseModal} />
            <PopupWidget
                url="https://calendly.com/evamarmx/reunion-de-60-minutos?primary_color=948867"
                /*
                * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                */
                rootElement={document.getElementById("root")}
                text="Click here to schedule!"
                textColor="#ffffff"
                color="#00a2ff"
                ref={calendlyPopupButton}
            />
            <Header />
            <main>{children}</main>
            <Footer />
        </>
    )
}

export default Layout;