import React,{ useState, useEffect, useRef } from "react";
import ReactPlayer from 'react-player'

const VideoPlayer = props => {
    //'video/sample.mp4'

    const playerRef = useRef();
    const [video, setVideo] = useState({
        url: props?.url || null,
        cover: props?.cover || '',
        caption: props?.caption || '',
        desc: props?.desc || '',
        isPlaying: false,
    });

    
    const handlePlay = () => {
        setVideo({ ...video, isPlaying: true });
    }
    const pauseVideo = () => {
        setVideo({ ...video, isPlaying: false });
    }

    return ( 
        <div className="video-player">
            { !video.isPlaying &&
                <div className={`video-player-cover ${video.isPlaying ? 'is-playing' : ''}`} style={{ backgroundImage: `url(${video.cover})` }}>
                    <div className="text-center">
                        <div className="video-player-cover--title text-center font-styled-bold text-white" dangerouslySetInnerHTML={{ __html: video.caption }}></div>
                        <p className="text-white" dangerouslySetInnerHTML={{ __html: video.desc}}>
                        </p>
                        <span className="icon icon--play" onClick={handlePlay}></span>
                    </div>
                </div>
            }
            <ReactPlayer 
                url={video.url} 
                playing={video.isPlaying} 
                controls={true}
                config={{
                    youtube: {
                        playerVars: { 
                            showinfo: 0,
                            controls: 0,
                            modestbranding: 0,
                            rel: 0,
                        }
                    },
                }}
                width={'100%'} 
                height={'100%'}
                onPlay={handlePlay} 
                ref={playerRef} />
        </div>
    )
}

export default VideoPlayer;