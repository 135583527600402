import React, { useState } from 'react';
import { useEffect } from 'react';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

const BeforeLeaveModal = (props) => {
    const { t } = useTranslation();
    const [show, setShow] = useState(props.show);

    useEffect(() => {
        setShow(props.show)
    },[props.show])

    const handleOpenCalendly = () => {
        props.onHandleOpenCalendly();
    }

    const closeModal = () => {
        props.onHandleClose();
    }

    return (
        <Modal
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Body className='py-5 px-5 bg-primary text-center text-white'>
                <h2 className='mt-5 text-uppercase font-styled-bold text-white size-title mb-4'>{t('modal.beforeLeave.title')}</h2>
                <Row className='justify-content-center'>
                    <Col xs='8'>
                        <p className='my-2 my-md-5'>
                            {t('modal.beforeLeave.description')}
                        </p>
                    </Col>
                </Row>
                <p>
                    <Button variant="secondary-high" className="text-white" onClick={handleOpenCalendly}>{t("buttons.appointment")}</Button>
                </p>
                <Button variant='text' className='text-white' onClick={closeModal}>{t('buttons.close')}</Button>
            </Modal.Body>
        </Modal>
    );
}

export default BeforeLeaveModal;