import { React } from "react";
import { Container, Row, Col, Button } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import ContactForm from "../../components/ContactForm/ContactForm";
import FAQsWidget from "../../components/FAQs/FAQs";

const FAQs = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <section className="bg-secondary py-5">
                <Container className='text-center text-md-start'>
                    <Row className='pt-4 justify-content-md-center'>
                        <Col>
                            <h2 className='text-center font-styled-bold text-white'>{t("faqs.title")}</h2>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="bg-white py-5">
                <Container className='text-md-start mt-5'>
                    <Row className='py-5'>
                        <Col md='3'>
                            <h5 className="pt-4"><b>CATEGORIES</b></h5>
                            <hr />
                            <ul className="list-ol">
                                <li><span>1</span> General</li>
                            </ul>
                        </Col>
                        <Col className="text-justify">
                            <FAQsWidget />
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export default FAQs;