import { React } from "react";
import { Container, Row, Col, Button } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import ContactForm from "../../components/ContactForm/ContactForm";
import FAQs from "../../components/FAQs/FAQs";
import Mosaic from "../../components/Mosaic/Mosaic";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";

const Community = () => {
    const { t } = useTranslation();

    const gallery = [
        {   title: "",
            subtitle: "",
            href:  "/images/community/1.jpg",
            thumb: "images/community/1.jpg" },
        {   title: "",
            subtitle: "",
            href:  "images/community/2.jpg",
            thumb: "images/community/2.jpg" },
        {   title: "",
            subtitle: "",
            href:  "images/community/3.jpg",
            thumb: "images/community/3.jpg" },
        {   title: "",
            subtitle: "",
            href:  "images/community/4.jpg",
            thumb: "images/community/4.jpg" },

        {   title: "",
            subtitle: "",
            href:  "images/community/5.jpg",
            thumb: "images/community/5.jpg" },
        {   title: "",
            subtitle: "",
            href:  "images/community/6.jpg",
            thumb: "images/community/6.jpg" },
        {   title: "",
            subtitle: "",
            href:  "images/community/7.jpg",
            thumb: "images/community/7.jpg" },
        {   title: "",
            subtitle: "",
            href:  "images/community/8.jpg",
            thumb: "images/community/8.jpg" },

        {   title: "",
            subtitle: "",
            href:  "images/community/9.jpg",
            thumb: "images/community/9.jpg" },
        {   title: "",
            subtitle: "",
            href:  "images/community/10.jpg",
            thumb: "images/community/10.jpg" },
        {   title: "",
            subtitle: "",
            href:  "images/community/11.jpg",
            thumb: "images/community/11.jpg" },
        {   title: "",
            subtitle: "",
            href:  "images/community/12.jpg",
            thumb: "images/community/12.jpg" },

    ];

    return (
        <Layout>
            <section className="community-hero bg-stripe bg-secondary-med pt-5">
                <Container className='text-center text-md-start'>
                    <Row className='pt-4 justify-content-md-center'>
                        <Col>
                        <VideoPlayer url='https://www.youtube.com/watch?v=Yc1uy5CWWic&rel=0' cover='video/experiencias-cover.png' caption={t("video.community.title")} desc={t("video.community.desc")} />
                            <p className="hero-intro hero-intro--extend text-center mt-5 pt-5 pt-sm-0" dangerouslySetInnerHTML={{ __html: t("community.intro") }}>
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className='text-center text-md-start pt-5 pb-2'>
                    <Row className='justify-content-md-center'>
                        <Mosaic className="mt-n5" images={gallery} />
                    </Row>
                </Container>
            </section>
            <section className="bg-conexion-light py-2">
                <Container className='text-center text-md-start mt-5'>
                    <Row className='py-5 text-center'>
                        <Col className="px-5 py-3">
                            <img className="mb-4" src="./images/img-conexion-intro-3.jpg" alt="" />
                        </Col>
                        <Col lg='8' className="px-5 py-3">
                            <h2 className="text-center mb-3">{t("community.app.title")}</h2>
                            <p className="text-center" dangerouslySetInnerHTML={{ __html: t("community.app.intro") }}></p>
                            <Row className='app-features pt-3 pb-5 text-start'>
                                <Col className="px-5 py-3" xl='6'>
                                    <Row className='align-items-center'>
                                        <Col xs='4' lg='3'>
                                            <img src="./images/ico-app-management.svg" alt="" />
                                        </Col>
                                        <Col xs='8' lg='9'>
                                            <h5>{t("community.app.feature1.title")}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: t("community.app.feature1.desc")}}></p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="px-5 py-3" xl='6'>
                                    <Row className='align-items-center'>
                                        <Col xs='4' lg='3'>
                                            <img src="./images/ico-app-booking.svg" alt="" />
                                        </Col>
                                        <Col xs='8' lg='9'>
                                            <h5>{t("community.app.feature2.title")}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: t("community.app.feature2.desc")}}></p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="px-5 py-3" xl='6'>
                                    <Row className='align-items-center'>
                                        <Col xs='4' lg='3'>
                                            <img src="./images/ico-app-experiences.svg" alt="" />
                                        </Col>
                                        <Col xs='8' lg='9'>
                                            <h5>{t("community.app.feature3.title")}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: t("community.app.feature3.desc")}}></p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="px-5 py-3" xl='6'>
                                    <Row className='align-items-center'>
                                        <Col xs='4' lg='3'>
                                            <img src="./images/ico-app-services.svg" alt="" /> 
                                        </Col>
                                        <Col xs='8' lg='9'>
                                            <h5>{t("community.app.feature4.title")}</h5>
                                            <p dangerouslySetInnerHTML={{ __html: t("community.app.feature4.desc")}}></p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="app-links justify-content-center">
                                <Col lg='3'>
                                    <Button href="https://apps.apple.com/mx/app/evamar-habitantes/id1522629181" target="_blank" variant="text" className="display-inline"><img width="120px" src="images/btn-app-store.png" alt="" /></Button>
                                </Col>
                                <Col lg='3'>
                                    <Button href="https://play.google.com/store/apps/details?id=mx.grupoevamar.appevamar&hl=es&gl=MX" target="_blank" variant="text" className="display-inline"><img height="40px" src="images/btn-play-store.png" alt="" /></Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="community-hero bg-secondary-med py-5">
                <Container className='text-center text-md-start'>
                    <Row className='pt-4 justify-content-md-center'>
                        <Col>
                            <VideoPlayer url='https://www.youtube.com/watch?v=LUiS0if1rGg&rel=0' cover='video/evamar-cover.png' />
                        </Col>
                    </Row>
                </Container>
            </section>
            <ContactForm />
            { /*
                <FAQs />
                */}
        </Layout>
    )
}

export default Community;