import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LightGallery from 'lightgallery/react';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const Mosaic = props => {

    const [images, setImages] = useState([]);

    useEffect(() => {
        setImages(props.images)
    },[])

    const onInit = () => {
    };

    return (
        <div className={props.className}>
            <LightGallery
                    onInit={onInit}
                    speed={500}
                    plugins={[lgThumbnail, lgZoom]}
                    selector='.gallery-item'
                    licenseKey='07ba75a5-bc30-4948-9c06-304e7a2f6194'
                >
                <Container>
                    <Row>
                    {
                        images.map((i,key) => 
                            <Col md="3" className="p-0 x mb-1 mb-md-0" key={key}>
                                <a className='gallery-item' href={i.href} data-src={i.thumb || i.href}>
                                    <div className="image-square" style={{ backgroundImage: `url("${i.thumb || i.href}")` }}>
                                        <div className="caption">
                                            { i.title &&
                                                <div className="caption--title">{i.title}</div>
                                            }
                                            { i.subtitle &&
                                                <div className="caption--subtitle">{i.subtitle}</div>
                                            }
                                        </div>
                                    </div>
                                </a>
                            </Col>
                        )
                    }
                    </Row>
                </Container>
            </LightGallery>

        </div>
    )
}

export default Mosaic;