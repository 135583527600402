const properties = [
    {
        id: 1,
        slug: "arena",
        name: "Arena",
        rooms: 1,
        meters: 48,
        squarefeets: 517,
        description: {
            es: [
                "1 recámara",
                "Cocina equipada",
                "Sala",
                "1 baño completo",
                "Terraza (7m2)",
                "1 cajón de estacionamiento",                
            ],
            en: [
                "1 bedroom",
                "Equipped kitchen",
                "Living room",
                "1 full bath",
                "Terrace (75sqf)",
                "1 parking space",
            ]
        },
        thumb: "./images/properties/arena/img-thumb.jpg",
        image: "./images/properties/arena/img-main.png",
    },
    {
        id: 2,
        slug: "bahia",
        name: "Bahía",
        rooms: 2,
        meters: 95,
        squarefeets: 1023,
        description: {
            es: [
                "2 recámaras",
                "Cocina equipada",
                "Sala",
                "2 baños completos",
                "Terraza (7m2)",
                "1 cajón de estacionamiento",
            ],
            en: [
                "2 bedrooms",
                "Equipped kitchen",
                "Living room",
                "2 full baths",
                "Terrace (75sqf)",
                "1 parking space",
            ]
        },
        thumb: "./images/properties/bahia/img-thumb.jpg",
        image: "./images/properties/bahia/img-main.png",
    },
    {
        id: 3,
        slug: "caracol",
        name: "Caracol",
        rooms: 3,
        meters: 104,
        squarefeets: 1119,
        description: {
            es: [
                "3 recámaras",
                "Cocina equipada",
                "Sala",
                "2 baños completos",
                "Terraza (7m2)",
                "1 cajón de estacionamiento",
            ],
            en: [
                "3 bedrooms",
                "Equipped kitchen",
                "Living room",
                "2 full baths",
                "Terrace (75sqf)",
                "1 parking space",
            ]
        },
        thumb: "./images/properties/caracol/img-thumb.jpg",
        image: "./images/properties/caracol/img-main.png", 
    }
];
export default properties;