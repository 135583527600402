export function triggerHellobox () {
    /*const open = () => {
        e.preventDefault();
        e.stopPropagation();*/
        var iFrame = document.getElementById('hellobox-widget');
        iFrame.contentWindow.postMessage(4,"*");
    /*}
    return open;*/
}

export default triggerHellobox;