import { React, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap"
import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import ContactForm from "../../components/ContactForm/ContactForm";
import FAQs from "../../components/FAQs/FAQs";
import Mosaic from "../../components/Mosaic/Mosaic";
import VideoPlayer from "../../components/VideoPlayer/VideoPlayer";
import PropertyCard from "../../components/PropertyCard/PropertyCard";
import { triggerHellobox } from "../../utils/utils";

import properties from "../../data/properties";


const Apartments = () => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        if(window.location.hash.length){
            setTimeout(() => {
                var element = document.querySelector(window.location.hash);                
                element?.scrollIntoView({block: "center"});            
            },500)
        }
    },[]);

    const interiorsGallery = [
        {   title: t("apartments.gallery.image1"),
            subtitle: "",
            href:  "images/img-apartments-mosaic-cocina.jpg",
            thumb: "images/img-apartments-mosaic-cocina.jpg" },
        {   title: t("apartments.gallery.image2"),
            subtitle: "",
            href:  "images/img-apartments-mosaic-bano.jpg",
            thumb: "images/img-apartments-mosaic-bano.jpg" },
        {   title: t("apartments.gallery.image3"),
            subtitle: "",
            href:  "images/img-apartments-mosaic-sala.jpg",
            thumb: "images/img-apartments-mosaic-sala.jpg" },
        {   title: t("apartments.gallery.image4"),
            subtitle: "",
            href:  "images/img-apartments-mosaic-recamara.jpg",
            thumb: "images/img-apartments-mosaic-recamara.jpg" },
    ];

    return (
        <Layout>
            <section className="apartments-hero bg-secondary-med bg-stripe bg-stripe--green py-2">
                <Container className='text-center text-md-start mt-md-5'>
                    <Row className='pt-5 text-center justify-content-center'>
                        <Col className="text-center">
                            <div className="hero-banner" style={{ backgroundImage: `url("images/img-apartments-hero2.jpg")`, backgroundSize: 'cover', backgroundPosition: 'center 75%' }}>
                                <h1 className="text-uppercase font-styled-bold text-white size-title mb-4" dangerouslySetInnerHTML={{ __html: t("apartments.hero.title") }}>
                                </h1>
                                <Button variant="secondary-high" className="text-white" onClick={triggerHellobox}>{t("buttons.discoverApt")}</Button>
                            </div>
                            <p className="hero-intro" dangerouslySetInnerHTML={{ __html: t("apartments.hero.intro") }}>
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className='text-center text-md-start'>
                    <Row className='mb-5 justify-content-md-center'>
                        <Col>
                            <VideoPlayer url='https://www.youtube.com/watch?v=8kWZekltfTM&rel=0' cover='video/interiores_que_conectan-cover.png' caption={t('video.apartments.title')} desc={t('video.apartments.desc')} />
                        </Col>
                    </Row>
                </Container>
                <Container id="list" className='text-center text-md-start mt-2 mb-5'>
                    {   properties.map((p,key) => 
                            <PropertyCard variant="card" property={p} key={key} />
                        )
                    }
                </Container>
                <Container className='text-center text-md-start pt-5 pb-2'>
                    <Row className='justify-content-md-center'>
                        <Mosaic className="mt-n5" images={interiorsGallery} />
                    </Row>
                </Container>
            </section>
            <ContactForm />
            { /*
                <FAQs />
                */}
        </Layout>
    )
}

export default Apartments;