import React, { useState } from "react";
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import mapStyle from "./MapStyle";


const MapContainer = (props) => {

    const {
        showingInfoWindow,
        activeMarker,
        selectedPlace,
      } = useState(null);

    var points = [
        {lat: 27.9670148, lng: -111.0156101},
		{lat: 27.970871,lng: -111.0189648},
		{lat: 27.967337, lng: -111.0164494},
		{lat: 27.9631975, lng: -111.0269085},
		{lat: 27.962701, lng: -111.0286713},
		{lat: 27.9596787, lng: -111.0349634},
		{lat: 27.9632381, lng: -111.0251464},
		{lat: 27.9582041, lng: -111.0870371},
    ]
    var bounds = new props.google.maps.LatLngBounds();
    for (var i = 0; i < points.length; i++) {
      bounds.extend(points[i]);
    }

    const onMarkerClick = () => {}
    const onSpotClick = () => {

    }
    const onInfoWindowClose = () => {}
    
    const mapLoaded = (mapProps, map) => {
        map.setOptions({
            styles: mapStyle
        })
        map.fitBounds(bounds);
    }

    return (
        <Map 
            google={props.google} 
            zoom={14} bounds={bounds}
            initialCenter={{
                lat: 27.971278,
                lng: -111.012778
            }}
            containerStyle={{ width: "calc(100% - var(--bs-gutter-x) * 1)" }}
            onReady={(mapProps, map) => mapLoaded(mapProps, map)}
        >
   
            <Marker 
                onClick={onMarkerClick}
                name={'evamar'} 
                icon={{
                    url: "/images/ico-map-marker.png",
                    //anchor: new props.google.maps.Point(16,16),
                    size: new props.google.maps.Size(50,69),
                    scaledSize: new props.google.maps.Size(50,69)
                }}
            />
            {
                points.map((item,key) => {
                    return (
                        <Marker 
                            onClick={onSpotClick}
                            position={{lat: item.lat, lng: item.lng}}
                            icon={{
                                url: "/images/ico-map-spot.png",
                                //anchor: new props.google.maps.Point(16,16),
                                size: new props.google.maps.Size(20,20),
                                scaledSize: new props.google.maps.Size(20,20)
                            }}
                            label={{
                                text: `${key+1}`,
                                color: '#fff',
                                fontSize: '12px'  
                            }}
                            key={key}
                        />
                    )
                })
            } 
   
          <InfoWindow onClose={onInfoWindowClose}>
              <div>
                <h1>{selectedPlace?.name}</h1>
              </div>
          </InfoWindow>

        </Map>
    );
}

export default GoogleApiWrapper({
    apiKey: ("AIzaSyBvSs172REoJDfGBI9XEWehmXlydhdcKEY")
  })(MapContainer)

//export default Map;